<template>
  <base-popup
      :popup-model="show"
      title="View Live links"
      :valid="!valid"
      :width="80"
      :show-save-button="false"
      @onCancelButtonClick="closeFilesPopupHandler"
  >
    <div class="font-weight-bold mb-2 mt-2">
      Payment Information
    </div>
    <v-card v-if="currentItem.methods">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
            <tr>
              <td>Payment Method</td>
              <td>{{ currentItem.methods ?  currentItem.methods.name : 'N/A'}}</td>
            </tr>
            <tr v-if="currentItem.payerId">
              <td>Paypal Payer ID</td>
              <td>{{currentItem.payerId}}</td>
            </tr>
            <tr v-if="currentItem.paymentId">
              <td>Paypal Payment ID</td>
              <td>{{currentItem.paymentId}}</td>
            </tr>
            <tr v-if="currentItem.refno">
              <td>2Checkout</td>
              <td>{{currentItem.refno}}</td>
            </tr>
            <tr v-if="currentItem.stripeId">
              <td>Stripe ID</td>
              <td>{{currentItem.stripeId}}</td>
            </tr>
            <tr v-if="currentItem.paddleId">
              <td>Checkout ID</td>
              <td>{{currentItem.paddleId}}</td>
            </tr>
            <tr v-if="currentItem.paddleOrderId">
              <td>Order ID</td>
              <td>{{currentItem.paddleOrderId}}</td>
            </tr>
            <tr v-if="currentItem.trxnId">
              <td>Trxn ID</td>
              <td>{{currentItem.trxnId}}</td>
            </tr>
            <tr v-if="currentItem.phone">
              <td>Phone</td>
              <td>{{currentItem.phone}}</td>
            </tr>
            <tr v-if="currentItem.paymentDate">
              <td>Payment Date</td>
              <td>{{getDateTime(currentItem.paymentDate)}}</td>
            </tr>
            <tr v-if="currentItem.updater">
              <td>Last Updated By</td>
              <td>{{currentItem.updater.name}}</td>
            </tr>
            <tr class="mt-1" v-if="currentItem.attachment">
              <td>Attachment:</td>
              <td>
                <template >
                  <a href="javascript:;">
                    <v-img
                        @click.stop.prevent="download(currentItem.attachment, `attachment-${currentItem.refcode}`)"
                        :src="currentItem.attachment" aspect-ratio="2"
                        contain
                        link
                    />
                  </a>
                </template>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <div class="font-weight-bold mb-2 mt-5">
      Live Links
    </div>
    <v-data-table
        :headers="headers"
        :items="currentItem.details"
        hide-default-footer
        class="elevation-1"
        :disable-pagination="true"
        :expanded.sync="expanded"
        show-expand
    >
      <template  v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <p style="margin: auto 0 !important">{{getBillingOn(item)}}</p>
        </td>
      </template>
      <template v-slot:item.orderId="{item}">
        {{item.sale_counter.orderId}}
      </template>
      <template v-slot:item.billingType="{item}">
        {{item.sale_counter.billingType}}
      </template>
      <template v-slot:item.date="{item}">
        {{getDate(item.sale_counter.date)}}
      </template>
      <template v-slot:item.billing="{item}">
        <span style="font-size: 12px" v-for="(item, key) in getLinks(item)" :key="key">{{item.liveLink}} <br></span>
      </template>
      <template v-slot:item.totalAmount="{item}">
        ${{item.total}}
      </template>
      <template v-slot:body.append>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="text-align: right"><h4>Sub Total</h4></td>
          <td style="text-align: right">${{currentItem.amount}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="text-align: right"><h4>Discount</h4></td>
          <td style="text-align: right">${{currentItem.discount}}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="text-align: right"><h4>Grand Total</h4></td>
          <td style="text-align: right">${{currentItem.totalAmount}}</td>
        </tr>
      </template>
    </v-data-table>
    <div class="font-weight-bold mb-2 mt-5">
      Mail Information
    </div>
    <v-card>
      <v-card-text>
        <div><strong>Payment Mail:&nbsp;</strong>{{currentItem.paymentMail}} times</div>
        <div><strong>Update Mail:&nbsp;</strong>{{currentItem.updateMail}} times</div>
        <div><strong>Removal Mail:&nbsp;</strong>{{currentItem.removalMail}} times</div>
        <div><strong>After Removal Mail:&nbsp;</strong>{{currentItem.afterRemovalMail}} times</div>
      </v-card-text>
    </v-card>
  </base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";
import mailTemplate from "../../json/mailTemplate.json"
import moment from "moment";

export default {
  name: "LinkPopup",
  mixins: [Popup],
  components: {BasePopup},
  data() {
    return {
      selectedItem: 0,
      mailTemplate: mailTemplate,
      expanded: [],
      headers: [
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Order Date',
          value: 'date'
        },
        {
          text: 'Type',
          value: 'billingType'
        },
        {
          text: 'Links',
          value: 'billing'
        },
        {
          text: 'Total',
          value: 'totalAmount',
          align: 'right'
        }
      ]
    }
  },
  methods: {
    closeFilesPopupHandler() {
      this.$emit('closePopupHandler');
    },
    liveLinkHandler(site) {
      window.open(site, '_blank');
    },
    getRefLink(refcode) {
      const api = process.env.VUE_APP_BASE_URL;
      return `${api}/direct-payment?refno=${refcode}`;
    },
    getLinks(item){
      return this.arrFilter(item.sale_counter.sales_counter_details);
    },
    getDate(date) {
      if (date) return moment(date).format('MMM D,  YYYY');

      return '';
    },
    getBillingOn(item) {
      const {sales_counter_details, billingType} = item.sale_counter;
      const arr = this.arrFilter(sales_counter_details);
      const arrMap = arr.map((data)=>data.site.site_url).join(', ');
      return `${billingType} ${arrMap}`;
    },
    arrFilter($arr) {
      return $arr.filter((data)=> data.isActive === 1);
    }
  }
}
</script>

<style scoped>

</style>