const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
const regexPhone = /^[0-9\-\+]{9,15}$/;

const isEmail = (email) => {
    return regexEmail.test(email);
};

const isPhone = (phone) => {
    return regexPhone.test(phone);
};

module.exports = {
    isEmail,
    isPhone
};
