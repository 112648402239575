<template>
  <v-progress-linear height="2" indeterminate class="ma-0 absolute" v-if="isLoading"></v-progress-linear>
</template>

<script>
export default {
  name: "PageProgress",
  props: [
    'isLoading'
  ]
}
</script>

<style scoped>

.absolute {
  position: absolute;
}

</style>
