<template>
  <base-popup
      :popup-model="show"
      :title="`Files Popup ${getOrder()}`"
      :valid="!valid"
      :width="56"
      :show-save-button="false"
      @onCancelButtonClick="closeFilesPopupHandler"
  >
    <v-card>
      <v-row>
        <v-col>
          <v-data-table
              v-for="(item, key) in currentItem.orderDetails"
              :key="key"
              :headers="headers"
              :items="getItems(item.site_id)"
              item-key="id"
              hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-chip
                        class="ma-2"
                        color="primary"
                        link
                        @click.prevent.stop="openSite(item.site.site_url)"
                    >
                      {{ item.site.site_url }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:item.file="{ item }">
              <span class="mdi mdi-file-pdf-outline theme--light indigo--text text--lighten-1" style="font-size: 40px;" v-if="item.format === 'application/pdf;base64'"></span>
              <span class="mdi mdi-file-image-outline theme--light indigo--text text--lighten-1" style="font-size: 40px;" v-else></span>
            </template>
            <template v-slot:item.size="{ item }">
              <v-chip
                  class="ma-2 white--text"
                  color="teal lighten-2"
              >
                {{ item.size }}KB
              </v-chip>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                  icon
                  color="red"
                  @click.prevent.stop="downloadBase64File(item.format, item.file, item.name)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";

export default {
  name: "FilesPopup",
  components: {BasePopup},
  mixins: [Popup],
  data() {
    return {
      headers: [
        {
          text: "Thumb",
          value: "file",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Size",
          value: "size",
        },
        {
          text: "Action",
          value: "action",
        },
      ],
      items: []
    }
  },
  methods: {
    closeFilesPopupHandler() {
      this.$emit('closePopupHandler');
    },
    getOrder() {
      return this.currentItem.order_id ? `(Order ID: #${this.currentItem.order_id})` : ''
    },
    openSite(site) {
      window.open(`http://www.${site}`, '_blank');
    },
    getItems(siteId) {
      if(this.currentItem.Attachments.length > 0) {
        return  this.currentItem.Attachments.filter(item => item.site_id === siteId);
      }
      return [];
    },
    downloadBase64File(contentType, base64Data, fileName) {
      const linkSource = `data:${contentType},${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
}
</script>

<style scoped>

</style>