import BaseOutlinedButton from "../buttons/BaseOutlinedButton";

export default {
  components: {BaseOutlinedButton},
  props: {
    'disabled': {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    'loading': {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    'small': {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    'text': {
      type: String,
      default: function () {
        return '';
      }
    },
    'toolbar': {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  computed: {
    isDisabled() {
      return this.disabled || this.loading;
    }
  },
  methods: {
    onButtonClick() {
      this.$emit('onButtonClick')
    }
  }
}
