<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid"
      :width="40"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveItemHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-card-text>
        <div class="text-subtitle-1">
          <v-icon>mdi-account</v-icon>
          {{ currentItem.name }}
        </div>
        <div class="text-subtitle-1">
          <span v-if="currentItem.skype"><v-icon>mdi-skype-business</v-icon> {{ currentItem.skype }}</span>&nbsp;
          <span v-if="currentItem.phone"><v-icon>mdi-card-account-phone</v-icon> {{ currentItem.phone }}</span>
        </div>
        <div class="text-subtitle-1" v-if="currentItem.address">
          <span><v-icon>mdi-location-enter</v-icon> {{ currentItem.address }}</span>
        </div>
        <br>
        <div v-if="currentItem.about">{{ currentItem.about }}</div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>Change User E-mail</v-card-title>

      <v-card-text>
        <v-text-field
            outlined
            dense
            color="deep-purple accent-4"
            hide-details="auto"
            type="text"
            v-model="currentItem.email"
            label="Email"
        />
      </v-card-text>
    </v-form>
  </base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";

export default {
  name: "UserPopup",
  components: {BasePopup},
  mixins: [Popup],
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Change Primary E-mail'
          : 'Add User'
    }
  }
}
</script>

<style scoped>

</style>