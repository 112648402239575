<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-card :flat="!showPageTitle" class="ma-2">
      <page-progress :is-loading="isLoading"/>
      <page-title v-if="showPageTitle" :icon="icon" :title="title"/>
      <v-container class="pt-0" fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
                v-model="selected"
                :data-scrollable="scrollable"
                :hide-default-footer="disableDefaultFooter"
                :disable-pagination="disablePagination"
                :footer-props="{'items-per-page-options': limitList}"
                :headers="headers"
                :items="data"
                :options.sync="pagination"
                :server-items-length="total"
                :sort-by="sort"
                :sort-desc="descending"
                :show-select="showSelect"
                v-sortable-table
                @sorted="sortHandler"
                class="elevation-1 mt-0"
                dense
            >
              <template v-slot:top>
<!--                <v-toolbar flat>-->
                  <v-row no-gutters  class="mt-7">
                    <v-col cols="12" md="6" sm="8">
                      <g-p-search
                          v-if="actions.load"
                          :loading="isLoading"
                          @applySearch="handleSearch"
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="4">
                      <add-button
                          v-if="showClearButton"
                          :text="clearButtonText"
                          :icon="clearButtonIcon"
                          :toolbar="true"
                          @onButtonClick="clearButtonHandler"
                          class="ma-2 justify-end"
                      />
                      <add-button
                          v-if="showAddButton"
                          :text="addButtonText"
                          :icon="addButtonIcon"
                          :toolbar="true"
                          @onButtonClick="openPopUp"
                          class="ma-2 justify-end"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mb-10 ma-1">
                    <g-filter-autocomplete
                        v-if="showInvoiceButton"
                        placeholder="Send Mail: Select a Template"
                        :search-items="mailTemplates"
                        @onChangeHandler = "onChangePaymentEmailHandler($event)"
                        class="ma-2"
                    />
                    <g-date-selector
                        v-if="showInvoiceButton || showSaleCounterButton || showShantoitSelector || showDatePicker"
                        @onChangeDateHandler="onChangeDateHandler"
                        class="ma-2"
                    />
                    <g-filter-autocomplete
                        v-if="showInvoiceButton || showSaleCounterButton"
                        placeholder="Select a User"
                        :search-items="allAdminUsers"
                        @onChangeHandler = "onChangeFilterHandler($event, 'searchUser')"
                        class="ma-2"
                    />
                    <g-filter-autocomplete
                        v-if="showInvoiceButton || showShantoitSelector"
                        placeholder="Select a Status"
                        :search-items="getPayStatus"
                        @onChangeHandler = "onChangeFilterHandler($event, 'paymentStatus')"
                        class="ma-2"
                    />

                    <g-filter-autocomplete
                        v-if="showSaleCounterButton"
                        placeholder="Select a Billing Types"
                        :search-items="billingTypes"
                        @onChangeHandler = "onChangeFilterHandler($event, 'billingType')"
                        class="ma-2"
                    />
                    <g-filter-autocomplete
                        v-if="showSaleCounterButton"
                        placeholder="Select a Status"
                        :search-items="payStatusOrderList"
                        @onChangeHandler = "onChangeFilterHandler($event, 'paymentStatus')"
                        class="ma-2"
                    />
                    <g-filter-autocomplete
                        v-if="showSaleCounterButton"
                        placeholder="Select a Order From"
                        :search-items="contacts"
                        @onChangeHandler = "onChangeFilterHandler($event, 'contactMail')"
                        class="ma-2"
                    />

                    <g-filter-autocomplete
                        v-if="showInvoiceButton"
                        placeholder="Select a Method"
                        :search-items="allPayMethods"
                        @onChangeHandler = "onChangeFilterHandler($event, 'payMethod')"
                        class="ma-2"
                    />
                    <g-filter-autocomplete
                        v-if="showInvoiceButton"
                        placeholder="Select a Email Type"
                        :search-items="paymentEmails"
                        @onChangeHandler = "onChangeFilterHandler($event, 'paymentEmail')"
                        class="ma-2"
                    />
                  </v-row>
<!--                </v-toolbar>-->
<!--                <v-toolbar flat class="mt-1" v-show="showMailBoxNewsLetter">-->
                  <v-row v-show="showMailBoxNewsLetter">
                    <v-col cols="12" md="4">
                      <g-p-auto-complete
                              :dense="true"
                              :outlined="true"
                              :status=true
                              :validation="false"
                              :search-value="templateType"
                              apiPath="news-letters"
                              search-label="Send Mail: Select a Template"
                              @clearSearchResult="newsLetterTemplateHandler"
                              @selectSearchResult="newsLetterTemplateHandler"
                              style="height: 36px"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        label="Select a Mail"
                        v-model="mailer"
                        :items="mailSenders"
                        item-value="id"
                        item-text="name"
                        outlined
                        dense
                        style="height: 36px"
                      />
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-checkbox
                              v-model="isSendMailToAll"
                              label="Send Mail to All"
                              color="indigo"
                              hide-details
                      />
                    </v-col>
                    <v-col cols="12" md="2">
                      <add-button
                              class="mt-1"
                              text="Send Mail"
                              icon="mdi-mail"
                              :loading="isNewsletterBtnLoading"
                              @onButtonClick="newsLetterHandler()"
                      />
                    </v-col>
                  </v-row>
<!--                </v-toolbar>-->
              </template>
              <template v-slot:footer.prepend v-if="showInvoiceButton || showSaleCounterButton">
                <v-subheader v-if="user && (user.role_id === 1 || getPermission(user))">Grand Total: &nbsp;<strong>${{totalAmount}} USD</strong></v-subheader>
              </template>
              <template v-slot:item.attachment="{ item }">
                <g-avatar v-if="item.attachment" :source="item.attachment"/>
                <g-avatar v-else/>
              </template>
              <template v-slot:item.paymentMethod="{ item }">
                <g-avatar v-if="item.methods.attachment" :source="item.methods.attachment"/>
                <p v-else>{{item.methods.name}}</p>
              </template>
              <template v-slot:item.siteUrl="{ item }">
                <v-chip
                    class="ma-2"
                    color="primary"
                    link
                    @click.prevent.stop="openSite(item.siteUrl)"
                >
                  {{ item.siteUrl }}
                </v-chip>
              </template>
              <template v-slot:item.pa="{ item }">
                <progress-circular :value="item.pa" color="primary"/>
              </template>
              <template v-slot:item.email="{ item }">
               <span v-if="item.totalOrder <=  3" class="client-type">New&nbsp;</span>{{item.email}}<v-icon color="orange" title="Special Client" v-if="item.specialClient && item.specialClient.type === 1">mdi-shield-star</v-icon><v-icon color="primary" title="Fixed Client" v-else-if="item.specialClient && item.specialClient.type === 0">mdi-shield-account-outline</v-icon><v-icon color="primary" title="Agency Client" v-else-if="item.specialClient && item.specialClient.type === 2">mdi-alpha-a-circle</v-icon> <img title="More Special Client" width="20" :src="require('../assets/img/double-star.png')" v-else-if="item.specialClient && item.specialClient.type === 4">
              </template>
              <template v-slot:item.da="{ item }">
                <progress-circular :value="item.da" color="teal"/>
              </template>
              <template v-slot:item.description="{ item }">
                <div v-html="item.description"></div>
              </template>
              <template v-slot:item.dr="{ item }">
                <progress-circular :value="item.dr" color="red"/>
              </template>
              <template v-slot:item.ur="{ item }">
                <progress-circular :value="item.ur" color="pink"/>
              </template>
              <template v-slot:item.status="{ item }">
                <global-switch-component v-if="!item.isDeleted" :current-item="item" @switchHandler="switchHandler"/>
                <g-payment-status-chip v-else text="Deleted" color="blue"/>
              </template>
              <template v-slot:item.isByDefault="{ item }">
                <switch-method-component v-if="!item.isDeleted" :current-item="item" @switchHandler="switchMethodHandler"/>
                <g-payment-status-chip v-else text="Deleted" color="blue"/>
              </template>
              <template v-slot:item.isCheckBrokenLink="{ item }">
                <switch-broken-component :current-item="item" @switchHandler="switchBrokenHandler" />
              </template>
              <template v-slot:item.googleNews="{ item }">
                {{ getItemStatus(item.googleNews) }}
              </template>
              <template v-slot:item.follow="{ item }">
                {{ getItemStatus(item.follow) }}
              </template>
              <template v-slot:item.casino="{ item }">
                {{ getItemStatus(item.casino) }}
              </template>
              <template v-slot:item.orderStatus="{ item }">
                <v-btn dark :color="getColor(item.status)">
                  {{ item.status }}
                </v-btn>
              </template>
              <template v-slot:item.billingType="{ item }">
                {{item.billingType}}
                <v-tooltip top :color="setLinkExpiryColor(item.linkExpiryDate)" v-if="item.billingType === 'Home Page Link Insertion on'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon :color="setLinkExpiryColor(item.linkExpiryDate)" v-bind="attrs" v-on="on">mdi-link
                    </v-icon>
                  </template>
                  <span>Link Expired on: {{item.linkExpiryDate ? getDate(item.linkExpiryDate) : 'Unlimited'}}</span>
                  </v-tooltip>
              </template>
              <template v-slot:item.orderDate="{ item }">
                {{getDate(item.date)}}
              </template>
              <template v-slot:item.jobDate="{ item }">
                {{getDate(item.jobDate)}}
              </template>
              <template v-slot:item.date="{ item }">
                {{getDate(item.date)}}
              </template>
              <template v-slot:item.user="{ item }">
                {{item.user && item.user.name ? item.user.name : ''}}
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{getDate(item.createdAt)}}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{getDate(item.created_at)}}
              </template>
              <template v-slot:item.updatedAt="{ item }">
                {{getDate(item.updatedAt)}}
              </template>
              <template v-slot:item.settingsName="{ item }">
                {{setGoogleSheetName(item.settingsName)}}
              </template>
              <template v-slot:item.totalAmount="{ item }">
                ${{item.totalAmount}}
              </template>
              <template v-slot:item.total="{ item }">
                ${{item.total}}
              </template>
              <template v-slot:item.adminPrice="{ item }">
                ${{item.adminPrice}}
              </template>
              <template v-slot:item.price="{ item }">
                ${{item.price}}
              </template>
              <template v-slot:item.linkPrice="{ item }">
                ${{item.linkPrice}}
              </template>
              <template v-slot:item.adultPrice="{ item }">
                ${{item.adultPrice}}
              </template>
              <template v-slot:item.unpaidAmount="{ item }">
                ${{item.unpaidAmount}}
              </template>
              <template v-slot:item.deletedAmount="{ item }">
                ${{item.deletedAmount}}
              </template>
              <template v-slot:item.removalMail="{ item }">
                {{item.removalMail}} times
              </template>
              <template v-slot:item.template="{ item }">
                {{item.template.name}}
              </template>
              <template v-slot:item.country="{ item }">
                {{getCountryName(item.country)}}
              </template>
              <template v-slot:item.jobStatus="{ item }">
                <g-payment-status-chip v-if="item.jobStatus === 1" text="Processing"/>
                <g-payment-status-chip v-else-if="item.jobStatus === 2" color="green" text="Completed"/>
                <g-payment-status-chip v-else-if="item.jobStatus === 3" color="lime" text="Canceled"/>
              </template>
              <template v-slot:item.type="{ item }">
                <g-payment-status-chip v-if="item.type === 1" text="Broken Links"/>
                <g-payment-status-chip v-else-if="item.type === 2" color="green" text="Newsletter"/>
                <g-payment-status-chip v-else-if="item.type === 3" color="lime" text="Canceled"/>
              </template>
              <template v-slot:item.afterRemovalMail="{ item }">
                {{item.afterRemovalMail}} times
              </template>
              <template v-slot:item.paymentStatus="{ item }">
                <g-payment-status-chip v-if="item.isDeleted && item.paymentStatus === 900" :text="getDeleteStatus(item.billingType,'Removed')" color="indigo"/>
                <g-payment-status-chip v-else-if="item.isDeleted" :text="getDeleteStatus(item.billingType,'Deleted')" color="blue"/>
                <template v-else-if="item.paymentStatus === 100">
                  <g-payment-status-chip/>
                  <v-chip color="green" text-color="white" x-small v-if="getDueStatus(item.createdAt)">{{getDueStatus(item.createdAt)}}</v-chip>
                </template>
                <g-payment-status-chip v-else-if="item.paymentStatus === 200" color="green" text="Paid"/>
                <g-payment-status-chip v-else-if="item.paymentStatus === 300" color="lime" text="Pending"/>
                <g-payment-status-chip v-else-if="item.paymentStatus === 400" color="green darken-4" text="Refund"/>
                <g-payment-status-chip v-else-if="item.paymentStatus === 600" color="orange" text="Republish"/>
              </template>
              <template v-slot:item.isPrepayment="{ item }">
                <g-payment-status-chip v-if="item.isPrepayment" :text="prepaymentText(item.isPrepayment)" color="deep-purple darken-3"/>
              </template>
              <template v-slot:item.wpToken="{ item }">
                <g-payment-status-chip v-if="item.wpToken" text="Yes" color="deep-purple darken-3"/>
                <g-payment-status-chip v-else text="No" color="red darken-2"/>
              </template>
              <template v-slot:item.refLink="{ item }">
                <v-chip
                    class="ma-2"
                    color="green"
                    text-color="white"
                    link
                    @click.prevent.stop="copyHandler(getRefLink(item.refcode))"
                >
                  Copy
                </v-chip>
              </template>
              <template v-slot:item.ahrefs="{item}">
                <v-chip
                    class="ma-2"
                    color="green"
                    text-color="white"
                    link
                    @click.prevent.stop="ahrefHandler(item.siteUrl)"
                >
                  Click
                </v-chip>
              </template>
              <template v-slot:item.liveLink="{item}">
                {{item.liveLink}}
                <v-chip
                    class="ma-2"
                    color="green"
                    text-color="white"
                    link
                    x-small
                    @click.prevent.stop="openNewTabHandler(item.liveLink)"
                >
                  Click
                </v-chip>
              </template>
              <template v-slot:item.user="{ item }">
                {{item.user && item.user.name ? item.user.name : ''}}
              </template>
              <template v-slot:item.actions="{item}">
                <row-action-menu-button
                    :action-columns="actionColumns"
                    :column="item"
                    :row="item"
                    :user="user"
                    :show-invoice-button="showInvoiceButton || showSaleCounterButton"
                    @actionButtonClick="handleActionButtonClick"/>
              </template>
              <template v-slot:item.siteOwnerId="{ item }">
                {{item.siteOwner && item.siteOwner.name ? item.siteOwner.name : ''}}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <component
        :is="popupComponent"
        ref="component"
        :basic-data="basicData"
        :item="editedItem"
        :show="showPopup"
        @closePopupHandler="closePopupHandler"
        @onPopupShow="openPopUp"
        @saveItemHandler="saveItemHandler"
    />

    <confirm-popup
        :show="showDeleteConfirm"
        confirm-event="onConfirmDelete"
        @closePopupHandler="showDeleteConfirm = false"
        @onConfirmDelete="deleteItem(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to delete this item?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showRemoveConfirm"
        confirm-event="onConfirmRemove"
        @closePopupHandler="showRemoveConfirm = false"
        @onConfirmRemove="removePostItem(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to remove this item?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmSync"
        @closePopupHandler="showConfirm = false"
        @onConfirmSync="syncItem(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to sync DR, Traffic etc in google sheet?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showConfirmMoz"
        confirm-event="onConfirmSyncMoz"
        @closePopupHandler="showConfirmMoz = false"
        @onConfirmSyncMoz="syncItemMoz(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to sync DA, PA in google sheet?</span>
      </div>
    </confirm-popup>
    <confirm-popup
            :show="showPrepaymentConfirm"
            confirm-event="onConfirmPrepaymentStatus"
            @closePopupHandler="showPrepaymentConfirm = false"
            @onConfirmPrepaymentStatus="onChangedPrepaymentStatus(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to change prepayment status <strong>{{currentSelectedItem && currentSelectedItem.isPrepayment === 1 ? 'Prepaid' : 'Completed'}}</strong> to <strong>{{currentSelectedItem && currentSelectedItem.isPrepayment === 1 ? 'Completed' : 'Prepaid'}}</strong>?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showDeleteForeverConfirm"
        confirm-event="onConfirmDelete"
        @closePopupHandler="showDeleteForeverConfirm = false"
        @onConfirmDelete="destroyItem(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to delete this item permanently?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showDeleteWithoutPostConfirm"
        confirm-event="onConfirmDelete"
        @closePopupHandler="showDeleteWithoutPostConfirm = false"
        @onConfirmDelete="deleteWithoutItem(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to delete this item without post delete?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showRestoreConfirm"
        confirm-event="onConfirmRestore"
        @closePopupHandler="showRestoreConfirm = false"
        @onConfirmRestore="restoreHandler(currentSelectedItem, true)"
    >
      <div>
        <span v-if="currentSelectedItem && currentSelectedItem.refcode">Are you sure to restore ref. code:&nbsp;<strong>{{currentSelectedItem.refcode}}</strong> and also this related order items?</span>
        <span v-if="currentSelectedItem && currentSelectedItem.orderId">Are you sure to restore order ID:&nbsp;<strong>{{currentSelectedItem.orderId}}</strong>?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showResendMailConfirm"
        confirm-event="onConfirmResendMail"
        @closePopupHandler="showResendMailConfirm = false"
        @onConfirmResendMail="resendPaymentMailHandler(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to send email to this mail ID: <span v-if="currentSelectedItem && currentSelectedItem.email">{{currentSelectedItem.email}}</span>?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showAfterReminderMailConfirm"
        confirm-event="onConfirmAfterReminderMail"
        @closePopupHandler="showAfterReminderMailConfirm = false"
        @onConfirmAfterReminderMail="afterReminderPaymentMailHandler(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to send email to this mail ID: <span v-if="currentSelectedItem && currentSelectedItem.email">{{currentSelectedItem.email}}</span>?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showRefundConfirm"
        confirm-event="onConfirmRefundInvoice"
        @closePopupHandler="showRefundConfirm = false"
        @onConfirmRefundInvoice="refundInvoiceHandler(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to refund this invoice no.: <span v-if="currentSelectedItem && currentSelectedItem.invoiceNo"><strong>{{currentSelectedItem.invoiceNo}} </strong>and total amount: <strong>${{currentSelectedItem.totalAmount}}</strong></span>?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showRepublishConfirm"
        confirm-event="onConfirmRepublish"
        @closePopupHandler="showRepublishConfirm = false"
        @onConfirmRepublish="republishHandler(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to republish all the articles of this order ID: <span v-if="currentSelectedItem && currentSelectedItem.orderId">{{currentSelectedItem.orderId}} and the provided email ID: {{currentSelectedItem.email}}  </span>?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showReminderMailConfirm"
        confirm-event="onConfirmResendMail"
        @closePopupHandler="showReminderMailConfirm = false"
        @onConfirmResendMail="reminderPaymentMailHandler(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to send reminder payment email to this mail ID: <span v-if="currentSelectedItem && currentSelectedItem.email">{{currentSelectedItem.email}}</span>?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showResetInvoiceConfirm"
        confirm-event="onConfirmResetInvoice"
        @closePopupHandler="showResetInvoiceConfirm = false"
        @onConfirmResetInvoice="onResetInvoiceHandler(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to reset ref. code:<strong v-if="currentSelectedItem && currentSelectedItem.refcode">{{currentSelectedItem.refcode}}</strong> all the payment information and also this related order items?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showResetWPAccess"
        confirm-event="onConfirmResetWPAccess"
        @closePopupHandler="showResetWPAccess = false"
        @onConfirmResetWPAccess="onResetResetWPAccessHandler(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to reset Site URL: <strong v-if="currentSelectedItem && currentSelectedItem.siteUrl">{{currentSelectedItem.siteUrl}}</strong> all WP access information?</span>
      </div>
    </confirm-popup>
    <confirm-popup
        :show="showMailConfirm"
        confirm-event="onConfirmMail"
        @closePopupHandler="showMailConfirm = false"
        @onConfirmMail="onChangePaymentEmailHandler(currentSelectedItem, true)"
    >
      <div>
        <span>Are you sure to send <strong v-if="currentSelectedItem && currentSelectedItem.name">{{currentSelectedItem.name}}</strong> to all selected invoices?</span>
      </div>
    </confirm-popup>
    <files-popup :show="showFilesPopup" :item="editedItem" @closePopupHandler="closePopupHandler"/>
    <link-popup :show="showLinkPopup" :item="editedItem" @closePopupHandler="closePopupHandler"/>
    <manual-payment-popup
        :show="showManualPaymentPopup"
        :item="editedItem"
        @saveItemHandler="saveItemHandler"
        @closePopupHandler="closePopupHandler"
    />
    <counter-link-popup :show="showCounterLinkPopup" :item="editedItem" @closePopupHandler="closePopupHandler"/>
    <email-details-popup :show="showEmailTimeliner" :item="editedItem" @closePopupHandler="closePopupHandler"/>
  </div>
</template>

<script>
import PageTitle from "./PageTitle";
import PageProgress from "./PageProgress";
import ConfirmPopup from "./popups/ConfirmPopup";
import SaveButton from "./buttons/SaveButton";
import RowActionMenuButton from "./RowActionMenuButton";
import AddButton from "./buttons/AddButton";
import GPSearch from "./GPSearch";
import ProgressCircular from "./ProgressCircular";
import ListPageMixin from "./mixins/listpage/ListPageMixin";
import GlobalSwitchComponent from "./GlobalSwitchComponent";
import FilesPopup from "./popups/FilesPopup";
import LinkPopup from "./popups/LinkPopup";
import mailTemplate from '../json/mailTemplate.json'
import Button from "./mixins/Button";
import ManualPaymentPopup from "./popups/ManualPaymentPopup";
import GPaymentStatusChip from "./GPaymentStatusChip";
import CounterLinkPopup from "./popups/CounterLinkPopup";
import GAvatar from "./GAvatar";
import GDateSelector from "./calendar/GDateSelector";
import GFilterAutocomplete from "./GFilterAutocomplete";
import GPAutoComplete from "./GPAutoComplete";
import EmailDetailsPopup from "./popups/EmailDetailsPopup";
import {countries} from "country-list-json";
import SwitchBrokenComponent from "./SwitchBrokenComponent";
import SwitchMethodComponent from "./SwitchMethodComponent";

export default {
  name: "ListPage",
  mixins: [ListPageMixin],
  data() {
    return {
      mailTemplate: mailTemplate,
    }
  },
  components: {
    SwitchMethodComponent,
    SwitchBrokenComponent,
    EmailDetailsPopup,
    GPAutoComplete,
    GFilterAutocomplete,
    GDateSelector,
    GAvatar,
    CounterLinkPopup,
    GPaymentStatusChip,
    ManualPaymentPopup,
    Button,
    LinkPopup,
    FilesPopup,
    GlobalSwitchComponent,
    ProgressCircular,
    GPSearch, AddButton, RowActionMenuButton, SaveButton, ConfirmPopup, PageProgress, PageTitle
  },
  methods: {
    getRefLink(refcode) {
       const api = process.env.VUE_APP_INVOICE_URL;
       return `${api}/extremebacklink/direct-payment?refno=${refcode}`;
     },
    getMailTemplate(item) {
      let template = this.setMailTemplate(item);
      this.copyClipboard(template);
    },
    setMailTemplate(item) {
     return `
          ${this.mailTemplate.title}

          ${this.mailTemplate.liveLinkText}: ${this.getRefLink(item.refcode)}

          ${this.mailTemplate.stepTitle}

          ${this.getSteps()}`;
    },
    getSteps() {
      let step = "";
      this.mailTemplate.steps.forEach(item=> {
        step += item + '\n'
      })
      return step;
    },
    copyHandler(linkUrl) {
      this.copyClipboard(linkUrl);
    },
    ahrefHandler(siteUrl) {
      if(!siteUrl) return;
      window.open(`https://app.ahrefs.com/site-explorer/overview/v2/subdomains/live?target=${siteUrl}`, '_blank');
    },
    copyClipboard(text) {
      const me = this;
      this.$copyText(text).then(function (e) {
        me.setSnackBar({text: 'Clipboard Copied!', type: 'info'})
      }, function (e) {
        me.setSnackBar({text: 'Can not Copied!', type: 'error'})
      })
    },
    prepaymentText(value) {
      if(value === 1){
        return 'Prepaid';
      } else if(value === 2) {
        return 'Completed';
      } else {
        return '';
      }
    },
    getDeleteStatus(type, text) {
      if(!type){
        return text;
      } else if(type === "Link Insertion on" || type === "Home Page Link Insertion on"){
        return 'Link Insert '+text;
      } else {
        return text;
      }
    },
    getCountryName(code){
      const country = countries.find((item) => item.code === code);
      if (!country) return '';
      return country.name;
    }
  }
}
</script>

<style>

.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 32px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.client-type{
  display: inline-block;
  background-color: #7048e8;
  color: #fff;
  padding: 4px 8px;
  font-size: 10px;
  border-radius: 50px;
}

</style>
