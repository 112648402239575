<template>
  <base-outlined-button
    :text="text"
    color="primary"
    icon="mdi-check"
    :disabled="disabled"
    :loading="loading"
    :toolbar="toolbar"
    :small="small"
    @onButtonClick="onButtonClick"
  />
</template>

<script>
  import ButtonMixin from '../mixins/Button';

  export default {
    name: "ApplyButton",
    mixins: [ButtonMixin],
    props: {
      text: {
        type: String,
        default: function () {
          return 'Apply'
        }
      },
      disabled: {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      loading: {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      toolbar: {
        type: Boolean,
        default: function () {
          return false;
        }
      }
    }
  }
</script>
