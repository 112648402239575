<template>
  <div>
    <template v-if="toolbar && $vuetify.breakpoint.xs">
      <v-btn
        fab
        x-small
        :color="color"
        class="ml-2"
        outlined
        :disabled="disabled"
        :loading="loading"
        @click="onButtonClick"
      >
        <v-icon dark>{{icon}}</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <v-chip
        outlined
        label
        class="ml-2"
        :small="small"
        :disabled="disabled || loading"
        :color="color"
        :loading="loading"
        @click="onButtonClick"
      >
        <v-icon left v-if="!loading">{{icon}}</v-icon>
        <template v-if="loading">
          <v-progress-circular
            :size="20"
            :width="2"
            color="primary"
            indeterminate
            class="mr-2"
          />
        </template>
        {{text}}
      </v-chip>
    </template>
  </div>
</template>

<script>
  export default {
    name: "BaseOutlinedButton",
    props: {
      'color': {
        type: String,
        default: function () {
          return 'primary';
        }
      },
      'icon': {
        type: String,
        default: function () {
          return '';
        }
      },
      'text': {
        type: String,
        default: function () {
          return '';
        }
      },
      'disabled': {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      'loading': {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      'small': {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      'toolbar': {
        type: Boolean,
        default: function () {
          return false;
        }
      }
    },
    methods: {
      onButtonClick() {
        this.$emit('onButtonClick')
      }
    }
  }
</script>
