import {isEmail, isPhone} from '../../helper/regex';

export default {
    data() {
        return {
            requiredFieldRule: [
                v => !!v || 'This field is required',
            ],
            customerNameRule: [
                v => !!v || 'This field is required',
                v => {
                    if (v && v.toLowerCase().indexOf('anonymous') !== -1) {
                        return 'Anonymous user is reserved. Please use any alternative name for customer.';
                    }
                    return true;
                }
            ],
            numberRule: [
                v => {
                    if (Number(v) === 0 || Number(v) === 0.00 || Number(v) > 0) {
                        return true;
                    } else {
                        return 'This field is required';
                    }
                }
            ],
            priceRule: [
                v => {
                    if (v > 0) {
                        return true;
                    } else {
                        return 'This field is must be greater than 0';
                    }
                }
            ],
            topupBalanceRule: [
                v => {
                    if (v >= 5) {
                        return true;
                    } else {
                        return 'Topup minimum $5 dollars.';
                    }
                }
            ],
            quantityRule: [
                v => {
                    if (v > 0) {
                        return true;
                    } else {
                        return 'This field is required';
                    }
                }
            ],
            requiredEmailRule: [
                v => {
                    if (!v) {
                        return 'This field is required';
                    }
                    return isEmail(v) || "E-mail is not valid"
                }
            ],
            emailRules:
                [
                    v => {
                        this.validationError = '';
                        if (v === '' || v === undefined || v === null) {
                            return true;
                        } else {
                            return isEmail(v) || "E-mail is not valid"
                        }

                    }
                ],
            phoneRules:
                [
                    v => {
                        if (v === '' || v === undefined || v === null) {
                            return true;
                        } else {
                            return isPhone(v) || "Only number is allowed & min 9 to max 15"
                        }

                    }
                ],
            passwordConfirmationRules:
                [
                    v => !!v || "Confirmation password is required",
                    v => v === this.signUp.password || "Password must match"
                ],
            adminPasswordConfirmationRules:
                [
                    v => !!v || "Confirmation password is required",
                    v => v === this.currentItem.password || "Password must match"
                ],
            passwordUpdateCRules:
                [
                    v => !!v || "Confirmation password is required",
                    v => v === this.cPData.password || "Password must match"
                ],
            rules: {
                required: value => !!value || "This field is required",
                min: v => v ? v.length >= 6 || "Min 6 characters" : ''
            }
        }
    }
}
