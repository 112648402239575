<template>
  <base-popup
    save-button-text="Yes"
    button-icon="mdi-send"
    :title="title"
    :popup-model="show"
    :width="maxWidth"
    :show-save-button="showSaveButton"
    @onCancelButtonClick="onCancelButtonClick"
    @onSaveButtonClick="onConfirmButtonClick"
  >
    <div class="confirm-popup mt-2">
      <slot />
    </div>
  </base-popup>
</template>

<script>
  import BasePopup from "./BasePopup";

  export default {
    name: "ConfirmPopup",
    components: {BasePopup},
    props: {
      show: {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      maxWidth: {
        type: Number,
        default: function () {
          return 26;
        }
      },
      confirmEvent: {
        type: String,
        default: function () {
          return '';
        }
      },
      title: {
        type: String,
        default: function () {
          return 'Confirmation';
        }
      },
      showSaveButton: {
        type: Boolean,
        default: function () {
          return true;
        }
      }
    },
    methods: {
      onConfirmButtonClick() {
        if (this.confirmEvent)
          this.$emit(this.confirmEvent);
      },
      onCancelButtonClick() {
        this.$emit('closePopupHandler');
      }
    }
  }
</script>

<style lang="scss">
  .confirm-popup {
    span {
      font-size: 20px !important;
      font-weight: 400 !important;
      line-height: 1 !important;
      letter-spacing: .02em !important;
      font-family: Roboto, sans-serif !important;
    }
  }

</style>
