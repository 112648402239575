<template>
  <v-autocomplete
      v-model="search"
      :items="searchItems"
      item-value="id"
      item-text="name"
      :placeholder="placeholder"
      clearable
      outlined
      dense
      class="select-field mr-2"
      style="height: 36px;"
      @change="onChangeHandler"
  />
</template>

<script>
export default {
  name: "GFilterAutocomplete",
  data() {
    return {
      search: ''
    }
  },
  props: {
    searchItems: {
      type: Array,
      default: function () {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: function () {
        return '';
      }
    }
  },
  methods: {
    onChangeHandler() {
      const arg = this.search ? this.search : '';
      this.$emit('onChangeHandler', arg);
    }
  }
}
</script>

<style scoped>

</style>