<template>
  <div :style="{'max-width':maxWidth}">
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="customDate"
        transition="scale-transition"
        offset-y
        nudge-right="20"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
            :large="large"
            outlined
            label
            v-bind="attrs"
            v-on="on"
            color="primary"
            style="height: 36px;"
        >
          <v-icon left>mdi-calendar</v-icon>
          {{selectedDateRangeType || 'Select Date'}}
          <v-icon v-if="selectedDateRangeType" small right>mdi-pencil</v-icon>
        </v-chip>
      </template>
      <v-card class="mx-auto">
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-list>
              <template v-for="(item, i) in options">
                <v-list-item @click="onDateTypeSelect(item)">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"/>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="i + 1 < options.length" :key="i"/>
              </template>
            </v-list>
          </v-window-item>
          <v-window-item :value="2">
            <v-row no-gutters>
              <v-col>
                <v-date-picker
                    v-model="customDate"
                    range
                    no-title
                    scrollable
                    color="primary"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-divider/>
                <v-card-actions>
                  <v-spacer/>
                  <cancel-button @onButtonClick="step=1"/>
                  <apply-button @onButtonClick="onCustomDateTypeSelect(customDate)" text="OK"/>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="3">
            <v-row no-gutters>
              <v-col>
                <v-date-picker
                    v-model="customMonth"
                    type="month"
                    no-title
                    scrollable
                    color="primary"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-divider/>
                <v-card-actions>
                  <v-spacer/>
                  <cancel-button @onButtonClick="step=1"/>
                  <apply-button @onButtonClick="onCustomMonthTypeSelect(customMonth)" text="OK"/>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import CancelButton from "../buttons/CancelButton";
import ApplyButton from "../buttons/ApplyButton";
import moment from "moment";

export default {
  name: "GDateSelector",
  components: {ApplyButton, CancelButton},
  data: () => ({
    customDate: [
      new Date().toISOString().substr(0, 7),
      new Date().toISOString().substr(0, 7)
    ],
    customMonth: new Date().toISOString().substr(0, 7),
    selectedDateRange: '',
    selectedDateRangeType: '',
    menu: false,
    step: 1
  }),
  props: {
    format: {
      type: String,
      default: function () {
        return 'DD/MM/YYYY';
      }
    },
    options: {
      type: Array,
      default: function () {
        return [
          {
            text: 'Today',
            value: 'today'
          },
          {
            text: 'Yesterday',
            value: 'yesterday'
          },
          {
            text: 'This Week',
            value: 'this_week'
          },
          {
            text: 'Last Week',
            value: 'last_week'
          },
          {
            text: 'Last 7 Days',
            value: 'last_seven_days'
          },
          {
            text: 'This Month',
            value: 'this_month'
          },
          {
            text: 'Last Month',
            value: 'last_month'
          },
          {
            text: 'Last Year',
            value: 'last_year'
          },
          {
            text: 'This Year',
            value: 'this_year'
          },
          {
            text: 'Custom Range',
            value: 'custom'
          },
          {
            text: 'Custom Month',
            value: 'custom_month'
          },
          {
            text: 'Reset Calendar',
            value: 'reset_calendar'
          }
        ];
      }
    },
    maxWidth: {
      type: String,
      default: function () {
        return '100%';
      }
    },
    large: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    value: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  watch: {
    menu: function (show) {
      if (!show) {
        this.step = 1;
      }
    },
    selectedDateRange: {
      handler: function (newSelection) {
        this.$emit('onChangeDateHandler', newSelection);
      },
      deep: true
    }
  },
  mounted() {
  /*  this.selectedDateRangeType = this.options.find(option => option.value === this.value).text;
    this.selectedDateRange = this.selectedDataHandler(this.value);*/
  },
  methods: {
    onDateTypeSelect(item) {
      if (item.value === 'custom') {
        this.step = 2;
      } else if (item.value === 'custom_month') {
        this.step = 3;
      } else {
        this.selectedDateRangeType = item.text;
        this.selectedDateRange = this.selectedDataHandler(item.value);
        this.menu = false;
      }
    },
    onCustomDateTypeSelect(_customDate) {
      const startDate = moment(_customDate[0]).startOf('days');
      const endDate = moment(_customDate[1]).endOf('days');
      this.selectedDateRange = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      };
      this.$refs.menu.save(_customDate);
      this.selectedDateRangeType = `${startDate.format(this.format)} To ${endDate.format(this.format)}`;
    },
    onCustomMonthTypeSelect(_customMonth){
      const startDate = moment(_customMonth).startOf('month');
      const endDate = moment(_customMonth).endOf('month');

      this.selectedDateRange = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      };
      this.$refs.menu.save(_customMonth);
      this.selectedDateRangeType = startDate.format('YYYY, MMMM');
    },
    selectedDataHandler(dateType) {
      let startDate = moment();
      let endDate = moment();

      switch (dateType) {
        case 'today':
          startDate = moment().startOf('days').format('YYYY-MM-DD');
          endDate = moment().endOf('days').format('YYYY-MM-DD');
          break;
        case 'yesterday':
          startDate = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
          endDate = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD');
          break;
        case 'this_week':
          startDate = moment().startOf('isoweek').format('YYYY-MM-DD');
          endDate = moment().endOf('isoweek').format('YYYY-MM-DD');
          break;
        case 'last_week':
          startDate = moment().subtract(1, 'week').startOf('isoweek').format('YYYY-MM-DD');
          endDate = moment().subtract(1, 'week').endOf('isoweek').format('YYYY-MM-DD');
          break;
        case 'last_seven_days':
          startDate = moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD');
          endDate = moment().endOf('days').format('YYYY-MM-DD');
          break;
        case 'this_month':
          startDate = moment().startOf('month').format('YYYY-MM-DD');
          endDate = moment().endOf('month').format('YYYY-MM-DD');
          break;
        case 'last_month':
          startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
          endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
          break;
        case 'last_year':
          startDate = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
          endDate = moment().subtract(1,'year').endOf('year').format('YYYY-MM-DD');
          break;
        case 'this_year':
          startDate = moment().startOf('year').format('YYYY-MM-DD');
          endDate = moment().endOf('year').format('YYYY-MM-DD');
          break;
        case 'reset_calendar':
          startDate = '';
          endDate = '';
          this.selectedDateRange = '';
          this.selectedDateRangeType = '';
          break;
        default:
          break;
      }

      return {
        startDate: startDate,
        endDate: endDate
      }
    }
  }
}
</script>

<style scoped>

</style>
