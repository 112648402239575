<template>
  <v-progress-circular
      :rotate="rotate"
      :size="size"
      :value="value"
      :width="width"
      :color="color"
  >
    {{ value }}
  </v-progress-circular>
</template>

<script>
export default {
  name: "ProgressCircular",
  props: {
    value: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 5
    },
    size: {
      type: Number,
      default: 40
    },
    rotate: {
      type: Number,
      default: 360
    },
    color: {
      type: String,
      default: function () {
        return "Primary";
      }
    }
  }
}
</script>

<style scoped>

</style>
