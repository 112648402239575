import { render, staticRenderFns } from "./BasePopup.vue?vue&type=template&id=339f47cb&scoped=true&"
import script from "./BasePopup.vue?vue&type=script&lang=js&"
export * from "./BasePopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339f47cb",
  null
  
)

export default component.exports