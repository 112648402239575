<template>
  <v-dialog v-model="popupModel" :max-width="maxWidth" persistent scrollable>
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      />
    </v-overlay>
    <v-card>
      <v-toolbar
        dense
        flat
      >
        <div class="custom-toolbar-title">
          {{ title }}
        </div>
        <v-spacer></v-spacer>
        <v-btn v-if="showCancelButton" icon @click="onCancelButtonClick">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <slot />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <cancel-button v-if="showCancelButton" text="Close" @onButtonClick="onCancelButtonClick"/>
        <base-outlined-button
          v-if="showSaveButton"
          :text="saveButtonText"
          color="primary"
          :icon="buttonIcon"
          :disabled="valid"
          :loading="loading"
          @onButtonClick="onSaveButtonClick"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import CancelButton from "../buttons/CancelButton";
  import SaveButton from "../buttons/SaveButton";
  import BaseOutlinedButton from "../buttons/BaseOutlinedButton";

  export default {
    name: "BasePopup",
    components: {BaseOutlinedButton, SaveButton, CancelButton},
    props: {
      title: {
        type: String,
        default: function () {
          return '';
        }
      },
      popupModel: {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      valid: {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      width: {
        type: Number,
        default: function () {
          return undefined;
        }
      },
      saveButtonText: {
        type: String,
        default: function () {
          return 'Save';
        }
      },
      buttonIcon: {
        type: String,
        default: function () {
          return 'mdi-content-save';
        }
      },
      loading: {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      showCancelButton: {
        type: Boolean,
        default: function () {
          return true;
        }
      },
      showSaveButton: {
        type: Boolean,
        default: function () {
          return true;
        }
      },
      overlay: {
        type: Boolean,
        default: function () {
          return false;
        }
      }
    },
    methods: {
      onCancelButtonClick() {
        this.$emit('onCancelButtonClick');
      },
      onSaveButtonClick() {
        this.$emit('onSaveButtonClick');
      }
    },
    computed: {
      maxWidth() {
        return `${(this.width || 32)}em`;
      }
    }
  }
</script>

<style scoped>

</style>
