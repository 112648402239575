<template>
  <v-text-field
      autofocus
      v-model="search"
      :prepend-inner-icon="icon"
      :placeholder="label"
      :search-input.sync="search"
      :hint="hint"
      :clearable="clearable"
      outlined
      dense
      class="ml-3"
      @click:clear="handleSearch('')"
  />
</template>

<script>
  import {Subject} from 'rxjs';
  import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';

  export default {
    name: "GPSearch",
    props: {
      loading: {
        type: Boolean,
        default: function () {
          return false;
        }
      },
      model: {
        type: String,
        default: function () {
          return '';
        }
      },
      icon: {
        type: String,
        default: function () {
          return 'search';
        }
      },
      label: {
        type: String,
        default: function () {
          return 'Search';
        }
      },
      clearable: {
        type: Boolean,
        default: function () {
          return true;
        }
      },
      hint: {
        type: String,
        default: function () {
          return 'Minimum two characters needed to search';
        }
      }
    },
    data() {
      return {
        term$: new Subject(),
        search: null,
        searchLength: 2,
      }
    },
    watch: {
      search(text) {
        this.term$.next(text);
      }
    },
    mounted() {
      this
        .term$
        .pipe(
          filter((term) => {
            return (term && term.length >= this.searchLength) || !term;
          }),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe(term => this.handleSearch(term));
    },
    methods: {
      handleSearch(searchTerm) {
        this.$emit('applySearch', searchTerm);
      }
    }
  }
</script>

<style scoped>

</style>
