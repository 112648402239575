<template>
  <v-avatar size="60px">
    <img :src="source" alt="Avatar">
  </v-avatar>
</template>

<script>
export default {
  name: "GAvatar",
  props: {
    source: {
      type: String,
      default: function () {
        return require('../assets/img/avatar.jpg')
      }
    }
  }
}
</script>

<style scoped>

</style>