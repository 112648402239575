export default {
    props: {
        title: {
            type: String,
            default: function () {
                return "";
            }
        },
        headers: {
            type: Array,
            default: function () {
                return [];
            }
        },
        columns: {
            type: Array,
            default: function () {
                return [];
            }
        },
        icon: {
            type: String,
            default: function () {
                return "";
            }
        },
        actions: {
            type: Object,
            default: function () {
                return {};
            }
        },
        basicData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        actionButtons: {
            type: Array,
            default: function () {
                return [];
            }
        },
        addButtonText: {
            type: String,
            default: function () {
                return "";
            }
        },
        addButtonIcon: {
            type: String,
            default: function () {
                return "mdi-plus";
            }
        },
        stateendpoint: {
            type: String,
            default: function () {
                return "";
            }
        },
        popupComponent: {
            type: Object,
            default: function () {
                return null;
            }
        },
        showDefaultActionButtons: {
            type: Boolean,
            default: function () {
                return true
            }
        },
        showAddButton: {
            type: Boolean,
            default: function () {
                return true;
            }
        },
        showClearButton: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        clearButtonText: {
            type: String,
            default: function () {
                return 'Clear All Links';
            }
        },
        clearButtonIcon: {
            type: String,
            default: function () {
                return 'mdi-recycle-variant';
            }
        },
        showPageTitle: {
            type: Boolean,
            default: function () {
                return true;
            }
        },
        descending: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        sort: {
            type: String,
            default: function () {
                return 'name';
            }
        },
        summaryOfStockCalc: {
            type: Object,
            default: function () {
                return null;
            }
        },
        showInvoiceButton: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        showShantoitSelector: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        showDatePicker: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        showSaleCounterButton: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        showSelect: {
            type:Boolean,
            default: function () {
                return false;
            }
        },
        mailType: {
            type: String,
            default: function () {
                return 'paymentMail'
            }
        },
        param1: {
            type: [Number, String],
            default: function () {
                return 0;
            }
        },
        showMailBoxNewsLetter: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        scrollable: {
            type: String,
            default: function () {
                return "";
            }
        },
        disablePagination: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        disableDefaultFooter: {
            type: Boolean,
            default: function () {
                return false
            }
        },
    },
}